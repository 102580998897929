/* eslint-disable */
import React from 'react'

import Agent from '../images/people/customer-service-agent-2.png'

import dottedPurpleBackground from '../images/backgrounds/dotted-purple-background.jpg'
import { StaticImage } from 'gatsby-plugin-image'

import {
  Columns,
  Column,
  LinkButton,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import NarrowBanner from '../components/NarrowBanner'

import aetna from '../images/brand-logos/aetna.svg'
import cigna from '../images/brand-logos/cigna.svg'
import regence from '../images/brand-logos/regence.svg'
import united from '../images/brand-logos/united-healthcare.svg'
import wellcare from '../images/brand-logos/wellcare.svg'
import mutual from '../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../components/GridLayouts/ResponsiveGrid'

const Home = () => {
  const { rotatedNumber } = useMapi()

  const brandLogos = [
    { src: aetna, alt: 'Aetna' },
    { src: cigna, alt: 'Cigna' },
    { src: regence, alt: 'Regence' },
    { src: united, alt: 'United Healthcare' },
    { src: wellcare, alt: 'Wellcare' },
    { src: mutual, alt: 'Mutual of Omaha' },
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Find in-network providers near you | Clearlinkinsurance.com',
          description:
            'Find and book an in-network medical providers near you.',
          canonical: 'https://clearlinkinsurance.com/about-us',
          robots: 'follow,index',
        },
        path: '/resources/open-and-general-enrollment-periods',
        promoCode: '169961',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Find in-network providers near you | Clearlinkinsurance.com',
    defaultRobots: 'follow,index',

    main: (
      <>
        <VariableContent
          mainContent={
            <>
              <Typography variant="h1" className="text-left-on-small">
                Medicare: Find a Doctor
              </Typography>
              <Typography variant="body" className="text-left-on-small">
                Our extensive database removes the hassle of finding a
                healthcare professional who takes your Medicare coverage. Search
                in-network doctors, pharmacies, and more below.
              </Typography>
              <LinkButton
                variant="hero"
                to="#specialties"
                color="pink"
                style={{ marginTop: '10px' }}
              >
                Find a Medical Professional
              </LinkButton>
            </>
          }
          alignMainContent="Center"
          className="dotted-purple-background"
          style={{
            backgroundImage: `url(${dottedPurpleBackground})`,
            color: '#fff',
          }}
        ></VariableContent>

        <div className="dual-button-banner">
          <NarrowBanner
            backgroundColor="#F004B9"
            leftButton={
              <LinkButton to={`tel:${rotatedNumber}`} color="white">
                Call Now to Speak to an Agent {rotatedNumber}
              </LinkButton>
            }
            rightButton={
              <LinkButton
                to="https://my.clearlinkinsurance.com/login"
                color="white"
              >
                User Portal Login
              </LinkButton>
            }
          />
        </div>

        <VariableContent
          mainContent={
            <>
              <Typography variant="h4">Our Insurance Brand Partners</Typography>
            </>
          }
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor={null}
          customLink={null}
          className="top-bottom-padding-48 brand-logos"
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <div id="specialties"></div>
        <VariableContent
          className="switch-bg-white-mobile overwrite-bg"
          mainContent={
            <>
              <Typography variant="h2">
                Medicare: Find a Provider by Speciality
              </Typography>
            </>
          }
          backgroundColor="light"
          alignMainContent="Center"
        >
          <Stack>
            <Columns mobileCarousel className="text-center center-buttons">
              <Column backgroundColor="white" className="center-buttons">
                <StaticImage
                  src="../images/illustrations/doctors.svg"
                  alt="primary care doctors"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons"
                />
                <Typography variant="body" className="large">
                  Primary Care Doctors
                </Typography>
                <LinkButton to="/find/find-a-doctor" color="secondary" outlined>
                  Search
                </LinkButton>
              </Column>
              <Column backgroundColor="white">
                <StaticImage
                  src="../images/illustrations/dentist.svg"
                  alt="dentists ratings"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons"
                />
                <Typography variant="body" className="large">
                  Dentists
                </Typography>
                <LinkButton
                  to="/find/find-a-dentist"
                  color="secondary"
                  outlined
                >
                  Search
                </LinkButton>
              </Column>
              <Column backgroundColor="white">
                <StaticImage
                  src="../images/illustrations/eye-doctors.svg"
                  alt="eye doctors"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons"
                />
                <Typography variant="body" className="large">
                  Eye Doctors
                </Typography>
                <LinkButton
                  to="/find/find-an-optometrist"
                  color="secondary"
                  outlined
                >
                  Search
                </LinkButton>
              </Column>
              <Column backgroundColor="white">
                <StaticImage
                  src="../images/illustrations/pharmacies.svg"
                  alt="pharmacies"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons"
                />
                <Typography variant="body" className="large">
                  Pharmacies
                </Typography>
                <LinkButton
                  to="/find/find-a-pharmacy"
                  color="secondary"
                  outlined
                >
                  Search
                </LinkButton>
              </Column>
              <Column backgroundColor="white">
                <StaticImage
                  src="../images/illustrations/hospitals.svg"
                  alt="hospitals"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons"
                />
                <Typography variant="body" className="large">
                  Hospitals
                </Typography>
                <LinkButton
                  to="/find/find-a-hospital"
                  color="secondary"
                  outlined
                >
                  Search
                </LinkButton>
              </Column>
              <Column backgroundColor="white">
                <StaticImage
                  src="../images/illustrations/savings.svg"
                  alt="savings"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="product-icons"
                />
                <Typography variant="body" className="large">
                  Savings
                </Typography>
                <LinkButton to="#" color="secondary" outlined>
                  Search
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <SplitContent
          backgroundColor="lightgray"
          image={<img src={Agent} alt="Customer service agent" />}
          mainContent={
            <>
              <Typography variant="h2">
                Clearlink Insurance Agency has you covered
              </Typography>
              <Typography variant="body">
                With more than 300 U.S.-based and U.S.-licensed agents
                nationwide, Clearlink Insurance Agency provides an unbiased look
                at coverage throughout all of the Medicare enrollment periods.
              </Typography>
              <Typography variant="body">
                We work with the top insurance providers in the nation,
                including Aetna, Cigna, and Regence, to bring you access to a
                wide variety of plans during the Initial Enrollment Period,
                Annual Enrollment Period, and Special Enrollment Periods.
              </Typography>
              <Typography variant="body">
                Use our online portal to compare plans and find the coverage you
                need that fits your lifestyle—and your budget.
              </Typography>
              <LinkButton
                to="https://www.sunfirematrix.com/app/consumer/clearlink/"
                color="pink"
              >
                Compare Plans
              </LinkButton>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
